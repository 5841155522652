//const Layout = () => import('@/views/teacherPortal/Layout');
//CLASS OVERVIEW
//WAITING ROOM
import nl from '@/utils/language/nl.json'

import TeacherGuard from '../../router/guards/teacher'
const WaitingRoom = () => import('@/views/teacherPortal/waitingRoom/WaitingRoom')

const Layout = () => import('@/views/Layout')

// CLASS MANAGEMENT#
//OVERVIEW
const Overview = () => import('@/views/teacherPortal/overview/Overview')
const MISSIONOVERVIEW = () => import('@/views/teacherPortal/overview/OverviewMission')
// MANAGEMENT
const MyClasses = () => import('@/views/teacherPortal/classManagement/MyClasses')
const EditClass = () => import('@/views/teacherPortal/classManagement/EditClass')
const CreateClass = () => import('@/views/teacherPortal/classManagement/CreateClass')
const Ranking = () => import('@/views/teacherPortal/classManagement/Ranking')

// MY SCHOOL
const MySchool = () => import('@/views/teacherPortal/mySchool/MySchool')
const EditTeacher = () => import('@/views/teacherPortal/mySchool/EditTeacher')

//CONVERSATION
const Conversation = () => import('@/views/teacherPortal/Conversation')
// GENERAL
const Instructions = () => import('@/views/teacherPortal/Instructions')
const Prizes = () => import('@/views/teacherPortal/Prizes')
const Help = () => import('@/views/teacherPortal/Help')
const Settings = () => import('@/views/teacherPortal/settings/Settings')
const EditDetails = () => import('@/views/teacherPortal/settings/EditDetails')

// TEST PAGE
const TestPage = () => import('@/views/examples/ComponentTest')

export const ROUTE_NAMES_TEACHER_PORTAL = Object.freeze({
  //CLASS OVERVIEW
  //WAITING ROOM
  WAITING_ROOM: 'WaitingRoom',
  // MANAGEMENT
  // OVERVIEW
  OVERVIEW: 'Overview',
  MISSIONOVERVIEW: 'MissionOverview',

  // CLASS MANAGEMENT
  RANKING: 'TeacherRanking',
  MY_CLASSES: 'MyClasses',
  EDIT_CLASS: 'EditClass',
  CREATE_CLASS: 'CreateClass',

  CONVERSATION: 'Conversation',

  // MY SCHOOL
  MY_SCHOOL: 'MySchool',
  EDIT_TEACHER: 'EditTeacher',

  // GENERAL
  INSTRUCTIONS: 'TeacherInstructions',
  DEMO_MISSION: 'DemoMission',
  PRIZES: 'TeacherPrizes',
  HELP: 'Help',
  SETTINGS: 'Settings',
  EDIT_DETAILS: 'EditDetails',

  // TEST PAGE
  TEST: 'TestPage'
})

export const teacherPortal = {
  path: `/${nl.ROUTE.TEACHER_PORTAL}`,
  meta: {
    guard: TeacherGuard,
    platform: 'teacherPortal'
  },
  component: Layout,
  redirect: `/${nl.ROUTE.TEACHER_PORTAL}/${nl.ROUTE.MY_CLASSES}`,
  children: [
    //Overview
    {
      path: `${nl.ROUTE.OVERVIEW}/:classId?`,
      name: ROUTE_NAMES_TEACHER_PORTAL.OVERVIEW,
      component: Overview,
      meta: {
        requireRegistration: true,
        disabled: false,
        hideIfNoClasses: true,
        title: 'Overzicht – Mijn MediaMasters',
      },
    },
    // CLASS OVERVIEW
    // WAITING ROOM
    {
      path: `${nl.ROUTE.WAITING_ROOM}`,
      name: ROUTE_NAMES_TEACHER_PORTAL.WAITING_ROOM,
      component: WaitingRoom,
      meta: {
        preRegistration: true,
        title: 'Wachtkamer – Mijn MediaMasters',
      },
    },
    //MISSIONOVERVIEW
    {
      path: `${nl.ROUTE.MISSION_OVERVIEW}`,
      name: ROUTE_NAMES_TEACHER_PORTAL.MISSIONOVERVIEW,
      component: MISSIONOVERVIEW,
      meta: {
        hideSidebar: true,
        disabled: true,
        title: 'Missie overzicht – Mijn MediaMasters',
      },
    },
    //CONVERSATION
    {
      path: `${nl.ROUTE.CONVERSATION}`,
      name: ROUTE_NAMES_TEACHER_PORTAL.CONVERSATION,
      component: Conversation,
      meta: {
        hideSidebar: false,
        disabled: true,
        title: 'Klassengesprek – Mijn MediaMasters',
      },
    },
    // MANAGEMENT
    // CLASS MANAGEMENT
    {
      path: `${nl.ROUTE.MY_CLASSES}`,
      name: ROUTE_NAMES_TEACHER_PORTAL.MY_CLASSES,
      component: MyClasses,
      meta: {
        title: 'Alle klassen – Mijn MediaMasters',
      },
    },
    {
      path: `${nl.ROUTE.MY_CLASSES}/${nl.ROUTE.EDIT}/:classId`,
      name: ROUTE_NAMES_TEACHER_PORTAL.EDIT_CLASS,
      component: EditClass,
      meta: {
        requireRegistration: true,
        title: 'Pas klas aan – Mijn MediaMasters',
      },
    },
    {
      path: `${nl.ROUTE.MY_CLASSES}/${nl.ROUTE.CREATE}`,
      name: ROUTE_NAMES_TEACHER_PORTAL.CREATE_CLASS,
      component: CreateClass,
      meta: {
        title: 'Maak klas aan – Mijn MediaMasters',
      },
    },
    {
      path: `${nl.ROUTE.RANKING}`,
      name: ROUTE_NAMES_TEACHER_PORTAL.RANKING,
      component: Ranking,
      meta: {
        disabled: false,
        postRanking: true,
        title: 'Ranglijst – Mijn MediaMasters',
      },
    },

    // MY SCHOOL
    {
      path: `${nl.ROUTE.MY_SCHOOL}`,
      name: ROUTE_NAMES_TEACHER_PORTAL.MY_SCHOOL,
      component: MySchool,
      meta: {
        requireRegistration: true,
        title: 'Mijn school – Mijn MediaMasters',
      },
    },
    {
      path: `${nl.ROUTE.MY_SCHOOL}/${nl.ROUTE.EDIT_TEACHER}/:teacherId`,
      name: ROUTE_NAMES_TEACHER_PORTAL.EDIT_TEACHER,
      component: EditTeacher,
      meta: {
        requireRegistration: true,
        title: 'Pas leraar aan – Mijn MediaMasters',
      },
    },

    // GENERAL
    {
      path: `${nl.ROUTE.INSTRUCTIONS}`,
      name: ROUTE_NAMES_TEACHER_PORTAL.INSTRUCTIONS,
      component: Instructions,
      meta: {
        title: 'Instructies – Mijn MediaMasters',
      },
    },
    {
      path: `${nl.ROUTE.PRIZES}`,
      name: ROUTE_NAMES_TEACHER_PORTAL.PRIZES,
      meta: {
        external: true,
        externalLink: 'https://mediamasters.nl/prijzen/',
        title: 'Prijzen – Mijn MediaMasters',
      },
    },
    {
      path: `${nl.ROUTE.HELP}`,
      name: ROUTE_NAMES_TEACHER_PORTAL.HELP,
      component: Help,
      meta: {
        title: 'Help – Mijn MediaMasters',
      },
    },
    {
      path: `${nl.ROUTE.SETTINGS}`,
      name: ROUTE_NAMES_TEACHER_PORTAL.SETTINGS,
      component: Settings,
      meta: {
        title: 'Mijn account – Mijn MediaMasters',
      },
    },
    {
      path: `${nl.ROUTE.SETTINGS}/${nl.ROUTE.EDIT_DETAILS}`,
      name: ROUTE_NAMES_TEACHER_PORTAL.EDIT_DETAILS,
      component: EditDetails,
      meta: {
        title: 'Pas details aan – Mijn MediaMasters',
      },
    },
    {
      path: 'test/components',
      name: ROUTE_NAMES_TEACHER_PORTAL.TEST,
      component: TestPage,
      meta: {
        disabled: true,
        // Todo: decide if you need auto login or another guard.
        // guard: AutoLoginGuard
      }
    }
  ]
}
