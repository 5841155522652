import Attachment from '@/models/Attachment'
import ExtraGame from '@/models/ExtraGame'
import Instruction from '@/models/Instruction'
import Mission from '@/models/Mission'
import Tag from '@/models/Tag'
import Video from '@/models/Video'

export const SET_EXTRA_GAMES = 'setExtraGames'
export const SET_TAGS = 'setTags'
export const SET_MISSIONS = 'setMissions'
export const SET_INSTRUCTIONS = 'setInstructions'
export const SET_ATTACHMENTS = 'setAttachments'
export const SET_VIDEOS = 'setVideos'
export const SET_MISSION_CATEGORIES = 'setMissionCategories'

/*

  SET_EXTRA_GAMES,
  SET_TAGS,
  SET_MISSIONS,
  SET_INSTRUCTIONS,


  */
export const SET_FAQS = 'setFaqs'
export const SET_FAQ_DETAILS = 'setFaqDetails'
export const SET_MEDIAQUIZZES = 'setMediaQuizzes'
export const SET_MISSION_VIDEOS = 'setMissionVideos'
export const mutations = {
  setMissions(state, data) {
    data.items = data.items.map(item => Mission.parseDataFromObject(item))
    state.missions = data
  },
  setMissionVideos(state, data) {
    state.missionVideos = data
  },
  setMissionCategories(state, data) {
    state.missionCategories = data
  },
  setExtraGames(state, data) {
    state.extraGames = data
  },
  setTags(state, data) {
    state.tags = data
  },
  setInstructions(state, data) {
    state.instructions = data
    state.instructions.items =
        data.items.map(item => Instruction.parseDataFromObject(item))
  },
  setAttachments(state, data) {
    state.attachments = data
    state.attachments.items = data.map(item => Attachment.parseDataFromObject(item))
  },
  setVideos(state, data) {
    state.videos = data
    state.videos.items = data.items.map(item => Video.parseDataFromObject(item))
  },
  setFaqs(state, data) {
    state.faqs = data
  },
  setMediaQuizzes(state, data) {
    state.mediaQuizzes = data
  }
}
