import HelpdeskGuard from '../../router/guards/helpdesk'
import nl from '@/utils/language/nl.json'

const Layout = () => import('@/views/Layout')

// SCHOOLS
const Schools = () => import('@/views/helpdesk/schools/SchoolOverview')
const SchoolCreate = () => import('@/views/helpdesk/schools/SchoolCreate')
const SchoolDetails = () => import('@/views/helpdesk/schools/SchoolDetails')
const SchoolEdit = () => import('@/views/helpdesk/schools/SchoolEdit')

// CLASSES
const ClassesOverview = () => import('@/views/helpdesk/classes/ClassesOverview')
const ClassDetails = () => import('@/views/helpdesk/classes/ClassDetails')
const ClassCreate = () => import('@/views/helpdesk/classes/ClassCreate')
const ClassEdit = () => import('@/views/helpdesk/classes/ClassEdit')
const ClassesOverviewInsight = () => import('@/views/helpdesk/classes/ClassesOverviewInsight')

// TEACHERS
const TeachersOverview = () => import('@/views/helpdesk/teachers/TeachersOverview')
const TeacherDetails = () => import('@/views/helpdesk/teachers/TeacherDetails')
const TeacherCreate = () => import('@/views/helpdesk/teachers/TeacherCreate')
const TeacherEdit = () => import('@/views/helpdesk/teachers/TeacherEdit')

//PROFESSIONALS
const ProfessionalOverview = () => import('@/views/helpdesk/professionals/ProfessionalOverview')

// RANKING
const Ranking = () => import('@/views/helpdesk/ranking/Ranking')

//STATS
const Stats = () => import('@/views/helpdesk/stats/Stats')

// BULK EDIT
const BulkEdit = () => import('@/views/helpdesk/bulkEdit/BulkEdit')

export const ROUTE_NAMES_HELPDESK = Object.freeze({
  // SCHOOLS
  SCHOOL_OVERVIEW: 'SchoolOverview',
  SCHOOL_CREATE: 'SchoolCreate',
  SCHOOL_DETAILS: 'SchoolDetails',
  SCHOOL_EDIT: 'SchoolEdit',

  // CLASSES
  CLASSES_OVERVIEW: 'ClassesOverview',
  CLASS_DETAILS: 'ClassDetails',
  CLASS_CREATE: 'ClassCreate',
  CLASS_EDIT: 'ClassEdit',
  CLASSES_OVERVIEW_INSIGHT: 'ClassesOverviewInsight',

  // TEACHERS
  TEACHERS_OVERVIEW: 'TeachersOverview',
  TEACHER_DETAILS: 'TeacherDetails',
  TEACHER_CREATE: 'TeacherCreate',
  TEACHER_EDIT: 'TeacherEdit',

  // PROFESSIONALS
  PROFESSIONALS_OVERVIEW: 'ProfessionalsOverview',

  // RANKING
  RANKING: 'Ranking',
  STATS: 'Stats',

  // BULK EDIT
  BULK_EDIT: 'BulkEdit',
})



export const helpdesk = {
  path: `/${nl.ROUTE.HELPDESK}`,
  // TODO: Add the auth guard. Please note that each module requires its own guard.
  meta: {
    platform: 'helpdesk',
    guard: HelpdeskGuard,
    // guard: TODO: Add a helpdesk guard.
  },
  redirect: `/${nl.ROUTE.HELPDESK}/${nl.ROUTE.SCHOOLS}`,
  component: Layout,
  children: [
    // SCHOOLS
    {
      path: `${nl.ROUTE.SCHOOLS}`,
      name: ROUTE_NAMES_HELPDESK.SCHOOL_OVERVIEW,
      component: Schools,
      meta: {
        title: 'Scholen – MediaMasters Helpdesk'
      },
    },
    {
      path: `${nl.ROUTE.SCHOOLS}/${nl.ROUTE.CREATE}`,
      name: ROUTE_NAMES_HELPDESK.SCHOOL_CREATE,
      component: SchoolCreate,
      meta: {
        title: 'Maak school aan – MediaMasters Helpdesk'
      },
    },
    {
      path: `${nl.ROUTE.SCHOOLS}/:schoolId`,
      name: ROUTE_NAMES_HELPDESK.SCHOOL_DETAILS,
      component: SchoolDetails,
      meta: {
        title: 'School details – MediaMasters Helpdesk'
      },
    },
    {
      path: `${nl.ROUTE.SCHOOLS}/:schoolId/${nl.ROUTE.EDIT}`,
      name: ROUTE_NAMES_HELPDESK.SCHOOL_EDIT,
      component: SchoolEdit,
      meta: {
        title: 'Pas school aan – MediaMasters Helpdesk'
      },
    },

    // CLASSES
    {
      path: `${nl.ROUTE.CLASSES}`,
      name: ROUTE_NAMES_HELPDESK.CLASSES_OVERVIEW,
      component: ClassesOverview,
      meta: {
        title: 'Klassenoverzicht – MediaMasters Helpdesk'
      },
    },
    {
      path: `${nl.ROUTE.CLASSES}/:classId/${nl.ROUTE.INSIGHT}`,
      name: ROUTE_NAMES_HELPDESK.CLASSES_OVERVIEW_INSIGHT,
      component: ClassesOverviewInsight,
      meta: {
        title: 'Inzicht voortgang – MediaMasters Helpdesk'
      },
    },
    {
      path: `${nl.ROUTE.CLASSES}/:classId`,
      name: ROUTE_NAMES_HELPDESK.CLASS_DETAILS,
      component: ClassDetails,
      meta: {
        title: 'Klas details – MediaMasters Helpdesk'
      },
    },
    {
      path: `${nl.ROUTE.CLASSES}/${nl.ROUTE.CREATE}`,
      name: ROUTE_NAMES_HELPDESK.CLASS_CREATE,
      component: ClassCreate,
      meta: {
        title: 'Maak klas aan – MediaMasters Helpdesk'
      },
    },
    {
      path: `${nl.ROUTE.CLASSES}/:classId/${nl.ROUTE.EDIT}`,
      name: ROUTE_NAMES_HELPDESK.CLASS_EDIT,
      component: ClassEdit,
      meta: {
        title: 'Pas klas aan – MediaMasters Helpdesk'
      },
    },

    // TEACHERS
    {
      path: `${nl.ROUTE.TEACHERS}`,
      name: ROUTE_NAMES_HELPDESK.TEACHERS_OVERVIEW,
      component: TeachersOverview,
      meta: {
        title: 'Leraren overzicht – MediaMasters Helpdesk'
      },
    },
    {
      path: `${nl.ROUTE.TEACHERS}/:teacherId`,
      name: ROUTE_NAMES_HELPDESK.TEACHER_DETAILS,
      component: TeacherDetails,
      meta: {
        title: 'Leraren details – MediaMasters Helpdesk'
      },
    },
    {
      path: `${nl.ROUTE.TEACHERS}/${nl.ROUTE.CREATE}`,
      name: ROUTE_NAMES_HELPDESK.TEACHER_CREATE,
      component: TeacherCreate,
      meta: {
        title: 'Maak leraar aan – MediaMasters Helpdesk'
      },
    },
    {
      path: `${nl.ROUTE.TEACHERS}/:teacherId/${nl.ROUTE.EDIT}`,
      name: ROUTE_NAMES_HELPDESK.TEACHER_EDIT,
      component: TeacherEdit,
      meta: {
        title: 'Pas leraar aan – MediaMasters Helpdesk'
      },
    },
    // PROFESSIONALS
    {
      path: `${nl.ROUTE.PROFESSIONAL}`,
      name: ROUTE_NAMES_HELPDESK.PROFESSIONALS_OVERVIEW,
      component: ProfessionalOverview,
      meta: {
        title: 'Professionals – MediaMasters Helpdesk'
      },
    },
    // RANKING
    {
      path: `${nl.ROUTE.RANKING}`,
      name: ROUTE_NAMES_HELPDESK.RANKING,
      component: Ranking,
      meta: {
        title: 'Rangschikking – MediaMasters Helpdesk'
      },
    },
    {
      path: `${nl.ROUTE.STATS}`,
      name: ROUTE_NAMES_HELPDESK.STATS,
      component: Stats,
      meta: {
        title: 'Statistieken – MediaMasters Helpdesk'
      },
    },
    // BULK EDIT
    {
      path: `${nl.ROUTE.BULK_EDIT}`,
      name: ROUTE_NAMES_HELPDESK.BULK_EDIT,
      component: BulkEdit,
      meta: {
        title: 'Bulkactie – MediaMasters Helpdesk'
      },
    },
  ]
}
