import { mutations } from './mutations'
import { actions } from './actions'
import { getters } from './getters'

export const state = () => ({
  teacherDetails: undefined,
  teachers: [],
  classes: [],
  classDetails: {},
  stats: [],
  rankingHelpdesk: null
})

const namespaced = false

export const helpdesk = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
