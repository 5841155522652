import { mutations } from './mutations'
import { actions } from './actions'
import { getters } from './getters'

export const state = () => ({
  selectedType: null,
  selectedItemId: null,
  foundClasses: [],
  selectedClasses: [],
  editingStatus: null,
  bulkEditExtraGames: [],
  bulkEditMissions: []
})

const namespaced = false

export const bulkEdit = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
