import nl from '@/utils/language/nl.json'
import AuthGuard from '../../router/guards/auth'

const Layout = () => import('@/views/auth/Layout')
const RegisterAs = () => import('@/views/auth/teacherPortal/register/RegisterAs')
const RegisterTeacher = () => import('@/views/auth/teacherPortal/register/RegisterTeacher')
const RegisterOther = () => import('@/views/auth/teacherPortal/register/RegisterOther')
const SelectSchool = () => import('@/views/auth/teacherPortal/register/SelectSchool')
const RegisterEmailSent = () => import('@/views/auth/teacherPortal/RegisterEmailSent')
const ConfirmTeacher = () => import('@/views/auth/teacherPortal/ConfirmTeacher')
const Login = () => import('@/views/auth/teacherPortal/Login')
const Logout = () => import('@/views/auth/teacherPortal/Logout')
const ForgotPassword = () => import('@/views/auth/teacherPortal/reset/ForgotPassword')
const ForgotPasswordEmailSent = () => import('@/views/auth/teacherPortal/reset/ResetLinkSent')
const ResetPassword = () => import('@/views/auth/teacherPortal/reset/ResetPassword')
const ExpiredPassword = () => import('@/views/auth/teacherPortal/reset/ExpiredPassword')

export const ROUTE_NAMES_AUTH = Object.freeze({
  // Register
  REGISTER_AS: 'RegisterAs',
  REGISTER_TEACHER: 'RegisterTeacher',
  REGISTER_OTHER: 'RegisterOther',
  SELECT_SCHOOL: 'SelectSchool',
  REGISTER_EMAIL_SENT: 'EmailSent',
  CONFIRM_TEACHER: 'ConfirmTeacher',

  // Login
  LOGIN: 'Login',
  FORGOT_PASSWORD: 'ForgotPassword',
  RESET_LINK_SENT: 'ResetLinkSent',
  RESET_PASSWORD: 'ResetPassword',
  EXPIRED_PASSWORD: 'ExpiredPassword',
  LOGOUT: 'Logout',
})

export const auth = {
  path: '/',
  meta: {
    guard: AuthGuard,
  },
  component: Layout,
  redirect: `/${nl.ROUTE.REGISTER}`,
  children: [
    {
      path: `${nl.ROUTE.REGISTER}/${nl.ROUTE.OTHER}`,
      name: ROUTE_NAMES_AUTH.REGISTER_OTHER,
      component: RegisterOther,
      title: 'Inschrijven – Mijn MediaMasters'
    },
    // Register
    {
      path: `${nl.ROUTE.REGISTER}`,
      name: ROUTE_NAMES_AUTH.REGISTER_AS,
      component: RegisterAs,
      meta: {
        title: 'Inschrijven – Mijn MediaMasters'
      }
    },
    {
      path: `${nl.ROUTE.REGISTER}/${nl.ROUTE.TEACHER}/:inviteToken?`,
      name: ROUTE_NAMES_AUTH.REGISTER_TEACHER,
      component: RegisterTeacher,
      meta: {
        title: 'Account aanmaken – Mijn MediaMasters'
      }
    },
    {
      path: `${nl.ROUTE.REGISTER}/${nl.ROUTE.SELECT_SCHOOL}`,
      name: ROUTE_NAMES_AUTH.SELECT_SCHOOL,
      component: SelectSchool,
      meta: {
        title: 'Selecteer school – Mijn MediaMasters'
      }
    },
    // {
    //   path: `register/confirm-email/:token`,
    //   name: ROUTE_NAMES_AUTH.REGISTER_EMAIL_SENT,
    //   component: ConfirmTeacher,
    // },
    {
      path: `${nl.ROUTE.REGISTER}/${nl.ROUTE.REGISTER_EMAIL_SENT}`,
      name: ROUTE_NAMES_AUTH.REGISTER_EMAIL_SENT,
      component: RegisterEmailSent,
      title: 'Inschrijven succesvol – Mijn MediaMasters'
    },

    // This route does not have /auth.
    {
      path: `/${nl.ROUTE.REGISTER}/${nl.ROUTE.VERIFY_EMAIL}/:token`,
      name: ROUTE_NAMES_AUTH.CONFIRM_TEACHER,
      component: ConfirmTeacher,
    },
    // {
    //   path: `${nl.ROUTE.REGISTER}/confirm-teacher`,
    //   name: ROUTE_NAMES_AUTH.CONFIRM_TEACHER,
    //   component: ConfirmTeacher,
    // },

    // Login
    // {
    //   path: `login`,
    //   redirect: `login/teacher`,
    //   name: ROUTE_NAMES_AUTH.LOGIN,
    //   component: Login,
    // },
    {
      path: `${nl.ROUTE.LOGIN}/:userType?`,
      name: ROUTE_NAMES_AUTH.LOGIN,
      component: Login,
      meta: {
        title: 'Inloggen – Mijn MediaMasters'
      }
    },
    {
      path: `${nl.ROUTE.FORGOT_PASSWORD}`,
      name: ROUTE_NAMES_AUTH.FORGOT_PASSWORD,
      component: ForgotPassword,
      meta: {
        title: 'Wachtwoord vergeten – Mijn MediaMasters'
      }
    },
    {
      path: `${nl.ROUTE.EXPIRED_PASSWORD}`,
      name: ROUTE_NAMES_AUTH.EXPIRED_PASSWORD,
      component: ExpiredPassword,
      meta: {
        title: 'Wachtwoord verlopen – Mijn MediaMasters'
      }
    },
    {
      path: `${nl.ROUTE.FORGOT_PASSWORD}/${nl.ROUTE.EMAIL_SENT}`,
      name: ROUTE_NAMES_AUTH.RESET_LINK_SENT,
      component: ForgotPasswordEmailSent,
      meta: {
        title: 'Herstellink verstuurd – Mijn MediaMasters'
      }
    },
    {
      path: `${nl.ROUTE.PASSWORD}/${nl.ROUTE.RESET}/:token`,
      name: ROUTE_NAMES_AUTH.RESET_PASSWORD,
      component: ResetPassword,
      meta: {
        title: 'Herstel verstuurd – Mijn MediaMasters'
      }
    },
    {
      path: `/${nl.ROUTE.LOGOUT}`,
      name: ROUTE_NAMES_AUTH.LOGOUT,
      component: Logout,
      meta: {
        title: 'Uitloggen – Mijn MediaMasters'
      }
    },
  ]
}
