import LocalDataHandler from '@/utils/handlers/LocalDataHandler'
import BaseGuard from '@/router/guards/base'
import { ROUTE_NAMES_TEACHER_PORTAL } from '../modules/teacherPortal'
import { ROUTE_NAMES_HELPDESK } from '../modules/helpdesk'
import { ROUTE_NAMES_CMS } from '../modules/cms'
import { ROUTE_NAMES_PROFESSIONAL } from '../modules/professional'
import { USER_TYPES } from '@/models/User'


class AuthGuard extends BaseGuard {
  constructor() {
    super()
    this.routeOnSuccessTeacher = ROUTE_NAMES_TEACHER_PORTAL.MY_SCHOOL
    this.routeOnSuccessHelpdesk = ROUTE_NAMES_HELPDESK.SCHOOL_OVERVIEW
    this.routeOnSuccessProfessional = ROUTE_NAMES_PROFESSIONAL.INSTRUCTIONS
    this.routeOnSuccessCMS = ROUTE_NAMES_CMS.FAQ
  }

  async handleRouteEnter() {
    // if (LocalDataHandler.getAccessToken()) {
    if (LocalDataHandler.isAuthenticated()) {
      const user = LocalDataHandler.getUser().type
      switch (user) {
        case USER_TYPES.TEACHER:
          return this.routeOnSuccessTeacher
        case USER_TYPES.PROFESSIONAL:
          return this.routeOnSuccessProfessional
        case USER_TYPES.CMS:
          return this.routeOnSuccessCMS
        case USER_TYPES.HELPDESK:
          return this.routeOnSuccessHelpdesk
      }
    }
    return new Promise(resolve => resolve(true))
    // }

    return this.refreshAccessToken().then(result => {
      if (result) {
        return true
      }

      /** keep in mind that the catch is handled in base guard (base.js) **/
      return true
    })
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new AuthGuard()
    }
    return this.instance
  }
}

export default AuthGuard.getInstance()
