// Formats given amount of bytes to human readable amount.
// a: the amount of bytes to convert.
// b: the amount of decimal places to show.
// formatBytes(1024);        1 KB
// formatBytes('1024');      1 KB
// formatBytes(1234);        1.21 KB
// formatBytes(1234, 3);     1.205 KB
export function formatBytes(a, b = 2) {
  const d = Math.floor(Math.log(a) / Math.log(1024))
  let humanReadableFileSize = a === 0
    ? '0 Bytes'
    : parseFloat((a / Math.pow(1024, d)).toFixed(Math.max(0, b))) +
    ' ' +
    ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
  // humanReadableFileSize = humanReadableFileSize.replace('.', ',')
  return humanReadableFileSize
}
