export const getters = {
  /** Loading spinner*/
  isPageLoading(state) {
    return state.isLoading
  },

  /** Basic Modal **/
  getBasicModalShown(state) {
    return state.isBasicModalShown
  },
  getBasicModalOptions(state) {
    return state.basicModalOptions
  },
  getActiveBasicModal(state) {
    return state.activeBasicModal
  },
  getActiveToast(state) {
    return state.activeToast
  },

  /** Menu **/
  getSelectedMenuId(state) {
    return state.selectedMenuId
  },

  /** API Error **/
  getApiError(state) {
    return state.apiError
  },
  /** global types **/
  getClassTypes(state) {
    return state.classTypes
  },
  getJokers(state) {
    return state.jokers
  },
  getWeekData(state) {
    return state.weekData
  }
}
