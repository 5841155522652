export default class Mission {
  constructor(
    id,
    name,
    description,
    tags,
    durationEstimated,
    // activatedFrom,
    // activatedUntil,
    status,
    categoryId,
    missionCategoryName,
    gamemode,
    suitableMiddleSchool,
    instructions,
    isActivated,
    createdAt,
    slideCount,
    difficulties
  ) {
    this.id = id
    this.name = name
    this.description = description
    this.tags = tags
    this.durationEstimated = durationEstimated
    // this.activatedFrom = activatedFrom
    // this.activatedUntil = activatedUntil
    this.status = status
    this.categoryId = categoryId
    this.missionCategoryName = missionCategoryName
    this.gamemode = gamemode
    this.suitibleMiddleSchool = suitableMiddleSchool
    this.instructions = instructions
    this.isActivated = isActivated
    this.createdAt = createdAt
    this.slideCount = slideCount,
    this.difficulties = difficulties
  }

  static parseDataFromObject(data) {
    return new Mission(
      data.id,
      data.name,
      data.description,
      data.tags,
      data.durationEstimated,
      // data.activatedFrom,
      // data.activatedUntil,
      data.status,
      data.categoryId,
      data.missionCategoryName,
      data.gamemode,
      data.suitibleMiddleSchool,
      data.instructions,
      data.isActivated,
      data.createdAt,
      data.slideCount,
      data.difficulties
    )
  }

  static dummyMissions()
  {

  }
}
