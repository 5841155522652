export default class Instruction {
  constructor(id, title, instruction, status, createdAt) {
    this.id = id
    this.title = title
    this.instruction = instruction
    this.createdAt = new Date(createdAt)
    this.status = status
  }

  static parseDataFromObject(data) {
    return new Instruction(data.id, data.title, data.content, data.isActivated, data.createdAt)
  }
}