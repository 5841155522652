
export const SET_MISSIONS = 'setMissions'

export const SET_MISSION_DETAILS = 'setMissionDetails'
export const SET_MISSION_CATEGORIES = 'setMissionCategories'
export const SET_SLIDE_TYPES = 'setSlideTypes'
export const SET_SLIDE_DETAILS = 'setSlideDetails'
export const SET_NETIQUETTE_TYPES = 'setNetiquetteSlideTypes'
export const SET_JOKER_TYPES = 'setJokerTypes'
export const mutations = {
  setMissions(state, data) {
    state.missions = data
  },
  setMissionDetails(state, data) {
    state.missionDetails = data
  },
  setMissionCategories(state, data) {
    state.missionCategories = data
  },
  setSlideTypes(state, data) {
    state.slideTypes = data
  },
  setSlideDetails(state, data) {
    state.slideDetails = data
  },
  setNetiquetteSlideTypes(state, data) {
    state.netiquetteSlideTypes = data
  },
  setJokerTypes(state, data) {
    state.jokerTypes = data
  }
}
