import { createRouter, createWebHistory, useRoute } from 'vue-router'
import { auth, ROUTE_NAMES_AUTH } from '@/router/modules/auth'
import { teacherPortal, ROUTE_NAMES_TEACHER_PORTAL } from '@/router/modules/teacherPortal'
import { professional, ROUTE_NAMES_PROFESSIONAL } from '@/router/modules/professional'
import { helpdesk } from '@/router/modules/helpdesk'
import { cms, ROUTE_NAMES_CMS } from '@/router/modules/cms'
import store from '../store/index.js'
import { computed } from 'vue'
import LocalDataHandler from '@/utils/handlers/LocalDataHandler'
import { USER_TYPES } from '@/models/User'
import { FETCH_TEACHER, CLEAR_TEACHER } from '@/store/modules/auth/actions'
import { trackRouter } from 'vue-gtag-next'



const routes = [].concat(auth, teacherPortal, helpdesk, cms, professional)

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    // We want the screen to always scroll to the top of the page.
    return { top: 0 }
  },
  routes
})

router.beforeEach(async(to, from, next) => {
  const currentProfile = computed(() => store.getters.getTeacherProfile)
  const user = LocalDataHandler.getUser()
  if (to.meta.platform === 'teacherPortal' || to.meta.platform === 'professional') {
    if (currentProfile.value && Object.keys(currentProfile.value).length === 0 && currentProfile.value.constructor === Object) {
      await store.dispatch(FETCH_TEACHER).then(response => {
      }).catch(() => {
      })
    }
  } else {
    store.dispatch(CLEAR_TEACHER)
  }


  if (user && (user.type === USER_TYPES.CMS || user.type === USER_TYPES.HELPDESK)) {

  }
  const cmsOrHelpdesk = user && (user.type === USER_TYPES.CMS || user.type === USER_TYPES.HELPDESK)
  const disabled = to.meta.disabled
  const preRegistration = to.meta.preRegistration
  const requireRegistration = to.meta.requireRegistration
  const registerPermission = !requireRegistration || currentProfile.value.isApproved
  const preRegisterOnly = (preRegistration && currentProfile.value.isApproved)// || (preRegistration && user.type === USER_TYPES.PROFESSIONAL && user.isApproved === true)
  const authenticated = LocalDataHandler.isAuthenticated()
  if (!cmsOrHelpdesk && (disabled || !registerPermission || preRegisterOnly)) {
    if (authenticated) {
      next({ name: ROUTE_NAMES_TEACHER_PORTAL.INSTRUCTIONS })
      return
    } else {
      next({ name: ROUTE_NAMES_AUTH.REGISTER_AS })
      return
    }
  }
  // Temporary.
  if (to.matched.length === 0) {
    if (authenticated) {
      //TODO check which platform
      next({ name: ROUTE_NAMES_TEACHER_PORTAL.INSTRUCTIONS })
      return
    } else {
      next({ name: ROUTE_NAMES_AUTH.REGISTER_AS })
      return
    }
  }
  /** User enters an invalid url **/
  if (to.matched.length === 0) {
    // todo: decide to use login or not found depending on projects
    // return next({name: ROUTE_NAMES_ROOT.NOT_FOUND});
    next({ name: ROUTE_NAMES_AUTH.REGISTER_AS })
    return
  }
  for (const route of to.matched) {
    if (route.meta && route.meta.guard) {

      /** Let each guard handle route access **/
      const result = await route.meta.guard.handleRouteEnter()
      if (result !== true) {
        next({ name: result })
        return
      }
    }
  }
  next()
  addMetaTags(to, from, next)
})

// add metatags for routes
// source with small adjustments: https://alligator.io/vuejs/vue-router-modify-head/
function addMetaTags(to, from, next) {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)
  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)
  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)
  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title
  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))
  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return false
  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map((tagDef) => {
    const tag = document.createElement('meta')
    Object.keys(tagDef).forEach((key) => {
      tag.setAttribute(key, tagDef[key])
    })
    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '')
    return tag
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag))
}

trackRouter(router)

export default router
