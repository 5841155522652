import { mutations } from './mutations'
import { actions } from './actions'
import { getters } from './getters'

export const state = () => ({
  /** My Classes **/
  schoolClasses: [],
  teacherProfile: [],
  teacherSettings: [],
  teacherRequests: {},
  questionsPage: {},
  instructionsPage: {},
  ranks: null,
  gameOverview: null
})

const namespaced = false

export const teacherPortal = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
