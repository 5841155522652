export const getters = {
  getSelectedType(state) {
    return state.selectedType
  },
  getSelectedItemId(state) {
    return state.selectedItemId
  },
  getFoundClasses(state) {
    return state.foundClasses
  },
  getSelectedClasses(state) {
    return state.selectedClasses
  },
  getEditingStatus(state) {
    return state.editingStatus
  },
  getBulkEditExtraGames(state) {
    return state.bulkEditExtraGames
  },
  getBulkEditMissions(state) {
    return state.bulkEditMissions
  }
}
