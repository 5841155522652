import { rankData } from '../../../utils/data/rankData'
import { mergeArrayObjects } from '@/utils/helpers/utils'
import LocalDataHandler from '@/utils/handlers/LocalDataHandler'

/** My Classes **/
export const SET_SCHOOL_CLASSES = 'setSchoolClasses'
export const SET_TEACHER_REQUESTS = 'setTeacherRequests'
/** Teacher settings **/
export const SET_TEACHER_PROFILE = 'setTeacherProfile'
export const SET_TEACHER_SETTINGS = 'setTeacherSettings'
export const SET_INSTRUCTIONS_PAGE = 'setInstructionsPage'
export const SET_QUESTIONS_PAGE = 'setQuestionsPage'
export const SET_FAQ_CATEGORIES = 'setFaqCategories'

export const SET_RANKS = 'setRanks'
export const SET_USER_RANKS = 'setUserRanks'
export const SET_GAME_OVERVIEW = 'setGameOverview'
export const SET_SELECTED_CLASS = 'setSelectedClass'


export const mutations = {
  /** My Classes **/
  setSchoolClasses(state, data) {
    state.schoolClasses = data
  },
  /** Teacher settings **/
  setTeacherProfile(state, data) {
    state.teacherProfile = data
  },
  setTeacherSettings(state, data) {
    state.teacherSettings = data
  },

  setTeacherRequests(state, data) {
    state.teacherRequests = data
  },
  setInstructionsPage(state, data) {
    state.instructionsPage = data
  },
  setQuestionsPage(state, data) {
    state.questionsPage = data
  },
  setFaqCategories(state, data) {
    state.faqCategories = data
  },
  setRanks(state, data) {
    state.ranks = mergeArrayObjects(data, rankData)
  },
  setUserRanks(state, data) {
    state.userRanks = data
  },
  setGameOverview(state, data) {
    if (data.extraBits?.extraGames?.maxBitsEarnable) {
      data.extraBits.extraGames.maxBitsEarnable = Math.round(data.extraBits.extraGames.maxBitsEarnable)
    }
    if (data.extraBits?.mediaquiz?.maxBitsEarnable) {
      data.extraBits.mediaquiz.maxBitsEarnable = Math.round(data.extraBits.mediaquiz.maxBitsEarnable)
    }
    state.gameOverview = data
  },
  setSelectedClass(state, data) {
    state.selectedClass = data
    LocalDataHandler.setSelectedClass(data)
  }
}
