import ApiHandler from '@/utils/handlers/ApiHandler'
import { renderSlot } from '@vue/runtime-core'
import { SET_API_ERROR, SET_CLASS_TYPES, SET_JOKERS, SET_WEEK_DATA } from './mutations'
export const GET_CLASS_TYPES = 'getClassTypes'
export const GET_CLASS_TYPES_HELPDESK = 'getClassTypesHelpdesk'
export const GET_CLASS_NAME_AVAILABLE = 'getClassNameAvailable'

export const GET_WEEK_DATA = 'getWeekData'
export const GET_JOKERS = 'getJokers'

export const actions = {
  getClassTypes({ commit }) {
    return ApiHandler.get('school/get-school-class-types')
      .then(response => {
        if (response) {
          commit(SET_CLASS_TYPES, response.data)
          return true
        }
        return false
      })
      .catch(error => {
        console.log(error)
        // TODO: Commit the API error.
      })
  },
  getClassTypesHelpdesk({ commit }) {
    return ApiHandler.get('helpdesk/class-types')
      .then(response => {
        if (response) {
          commit(SET_CLASS_TYPES, response.data)
          return true
        }
        return false
      })
      .catch(error => {
        console.log(error)
      })
  },
  getClassNameAvailable({ commit }, className) {
    const formData = new FormData()
    formData.append('schoolClassName', className)
    return ApiHandler.post('teacher/school-class-name-available', formData)
      .then(response => {
        return (response.data && response.data.schoolClassNameAvailable)
      }).catch(error => {
        console.log(error.response)
      })
  },
  getWeekData({ commit }) {
    return ApiHandler.get('global-settings/get-week-data').then(response => {
      if (response) {
        commit(SET_WEEK_DATA, response.data)
        return true
      }
      return false
    }).catch(error => {
      console.log(error.response.data)
    })
  },
  getJokers({ commit }) {
    return ApiHandler.get('cms/mission/get-jokers-types').then(response => {
      if (response) {
        commit(SET_JOKERS, response.data)
        return true
      }
      return false
    }).catch(error => {
      console.log(error.response.data)
      commit(SET_API_ERROR, error.response.data)
    })
  },
}
