import { createApp } from 'vue'
import i18n from '@/setup/i18n/i18n'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGtag from 'vue-gtag-next'
// import ApiHandler from '@/utils/handlers/ApiHandler'

const vueApp = createApp(App)

vueApp.use(i18n)
vueApp.use(store)
vueApp.use(router)

if (process.env.VUE_APP_GTAG) {
  vueApp.use(VueGtag, {
    property: {
      id: process.env.VUE_APP_GTAG,
      // NOTE: THIS MUST BE FALSE OTHERWISE PAGE VIEWS ARE COUNTED DOUBLE. trackRouter() FUNCTION IN THE ROUTER FILE HANDLES SENDING PAGE VIEW EVENTS TO GOOGLE ANALYTICS!
      params: {
        send_page_view: false,
      }
    }
  })
}

vueApp.mount('#app')


