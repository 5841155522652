import { GET_MISSIONS_INSIGHT } from '../../helpdesk/actions'

export const getters = {
  getMissions(state) {
    return state.missions
  },
  getMissionDetails(state) {
    return state.missionDetails
  },
  getMissionCategories(state) {
    return state.missionCategories
  },
  getSlideTypes(state) {
    return state.slideTypes
  },
  getSlideDetails(state) {
    return state.slideDetails
  },
  getNetiquetteSlideTypes(state) {
    return state.netiquetteSlideTypes
  },
  getJokerTypes(state) {
    return state.jokerTypes
  }
}