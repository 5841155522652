export const SET_SELECTED_TYPE = 'setSelectedType'

export const mutations = {
  setSelectedType(state, data) {
    state.selectedType = state.selectedType === data ? null : data
    state.selectedItemId = null
  },
  setSelectedItemId(state, data) {
    state.selectedItemId = state.selectedItemId === data ? null : data
  },
  setFoundClasses(state, data) {
    state.foundClasses = data
  },
  setBulkEditMissions(state, data) {
    state.bulkEditMissions = data
  },
  setBulkEditExtraGames(state, data) {
    state.bulkEditExtraGames = data
  },
  setSelectedClass(state, data) {
    state.selectedClasses = data
  },
  toggleSelectedClass(state, data) {
    const classId = data.id
    console.log("toggling class", classId);
    if (state.selectedClasses.find((item) => item.id === classId)) {
      state.selectedClasses.splice(state.selectedClasses.indexOf(data), 1)
    } else {
      state.selectedClasses.push(data)
    }
    //added to force update
    state.selectedClasses = [...state.selectedClasses]
  },
  removeSelectedClass(state, data) {
    state.selectedClasses = state.selectedClasses.filter((item) => item.id === classId)
  },
  addSelectedClass(state, data) {
    const classId = data.id
    if (!state.selectedClasses.find((item) => item.id === classId)) {
      state.selectedClasses.push(data)
    }
    state.selectedClasses = [...state.selectedClasses]
  },
  setEditingStatus(state, data) {
    state.editingStatus = data
  },
  reset(state) {
    state.selectedType = null
    state.selectedItemId = null
    state.foundClasses = []
    state.bulkEditExtraGames = []
    state.selectedClasses = []
  }
}
