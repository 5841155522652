import { mutations } from './mutations'
import { actions } from './actions'
import { getters } from './getters'

export const state = () => ({
  teacherTypes: [],
  searchedSchools: [],

  /** Login **/
  isAuthenticated: false,
  authError: '',
  teacherProfile: {},
})

const namespaced = false

export const auth = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
