import { USER_TYPES } from '@/models/User'

export const USER_TYPE_IDS = {
  TEACHER: 1,
  PROFESSIONAL: 2,
  CMS: 3,
  HELPDESK: 4,
}

export function scopeToId(scope) {
  switch (scope) {
    case USER_TYPES.TEACHER:
      return USER_TYPE_IDS.TEACHER
    case USER_TYPES.CMS:
      return USER_TYPE_IDS.CMS
    case USER_TYPES.HELPDESK:
      return USER_TYPE_IDS.HELPDESK
    case USER_TYPES.PROFESSIONAL:
      return USER_TYPE_IDS.PROFESSIONAL
  }
}

export function idToScope(id) {
  switch (id) {
    case USER_TYPE_IDS.TEACHER:
      return USER_TYPES.TEACHER
    case USER_TYPE_IDS.CMS:
      return USER_TYPES.CMS
    case USER_TYPE_IDS.HELPDESK:
      return USER_TYPES.HELPDESK
    case USER_TYPE_IDS.PROFESSIONAL:
      return USER_TYPES.PROFESSIONAL
  }
}