export const rankData = [
  {
    id: 1,
    nameKey: 'RANKS.TYPES.BRONZE',
    shieldImageSrc: require('../../assets/img/img_bronze.png'),
  },
  {
    id: 2,
    nameKey: 'RANKS.TYPES.SILVER',
    shieldImageSrc: require('../../assets/img/img_silver.png'),
  },
  {
    id: 3,
    nameKey: 'RANKS.TYPES.GOLD',
    shieldImageSrc: require('../../assets/img/img_gold.png'),
  },
  {
    id: 4,
    nameKey: 'RANKS.TYPES.DIAMOND',
    shieldImageSrc: require('../../assets/img/img_platinum.png'),
  },
  {
    id: 5,
    nameKey: 'RANKS.TYPES.BRILLIANT',
    shieldImageSrc: require('../../assets/img/img_legendary.png'),
  }
]
