import { mutations } from './mutations'
import { actions } from './actions'
import { getters } from './getters'

export const state = () => ({
  missionDetails: {},
  missions: [],
  missionCategories: [],
  slideTypes: [],
  netiquetteSlideTypes: [],
  slideDetails: null,
  jokerTypes: [],
})

const namespaced = false

export const missions = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
