//const Layout = () => import('@/views/teacherPortal/Layout');
//CLASS OVERVIEW
//WAITING ROOM
import ProfessionalGuard from '../../router/guards/professional'
import nl from '@/utils/language/nl.json'

const Layout = () => import('@/views/Layout')


const Overview = () => import('@/views/teacherPortal/overview/OverviewProfessional')

// GENERAL
const Instructions = () => import('@/views/teacherPortal/Instructions')
const Help = () => import('@/views/teacherPortal/Help')

//Settings
const Settings = () => import('@/views/teacherPortal/settings/Settings')
const EditDetails = () => import('@/views/teacherPortal/settings/EditDetails')

//Waiting room
const WaitingRoom = () => import('@/views/teacherPortal/waitingRoom/WaitingRoom')


export const ROUTE_NAMES_PROFESSIONAL = Object.freeze({

  // GENERAL
  OVERVIEW: 'OverviewProf',
  INSTRUCTIONS: 'InstructionsProf',
  HELP: 'HelpProf',
  SETTINGS: 'SettingsProf',
  EDIT_DETAILS: 'EditDetailsProf',
  WAITING_ROOM: 'WaitingRoomProf',

})

export const professional = {
  path: `/${nl.ROUTE.PROFESSIONAL}`,
  meta: {
    guard: ProfessionalGuard,
    platform: 'professional'
  },
  component: Layout,
  redirect: `/${nl.ROUTE.PROFESSIONAL}/${nl.ROUTE.TEACHER_INSTRUCTIONS}`,
  children: [
    {
      path: `${nl.ROUTE.OVERVIEW}`,
      name: ROUTE_NAMES_PROFESSIONAL.OVERVIEW,
      component: Overview,
      meta: {
        disabled: false,
        title: 'Professionals – Mijn MediaMasters'
      }
    },
    // GENERAL
    {
      path: `${nl.ROUTE.WAITING_ROOM}`,
      name: ROUTE_NAMES_PROFESSIONAL.WAITING_ROOM,
      component: WaitingRoom,
      meta: {
        preRegistration: true,
        title: 'Wachtkamer – Mijn MediaMasters'
      },
    },
    {
      path: `${nl.ROUTE.INSTRUCTIONS}`,
      name: ROUTE_NAMES_PROFESSIONAL.INSTRUCTIONS,
      component: Instructions,
      meta: {
        title: 'Instructies – Mijn MediaMasters'
      },
    },
    {
      path: `${nl.ROUTE.HELP}`,
      name: ROUTE_NAMES_PROFESSIONAL.HELP,
      component: Help,
      meta: {
        title: 'Help – Mijn MediaMasters'
      },
    },
    {
      path: `${nl.ROUTE.SETTINGS}`,
      name: ROUTE_NAMES_PROFESSIONAL.SETTINGS,
      component: Settings,
      meta: {
        title: 'Mijn account – Mijn MediaMasters'
      },
    },
    {
      path: `${nl.ROUTE.SETTINGS}/${nl.ROUTE.EDIT_DETAILS}`,
      name: ROUTE_NAMES_PROFESSIONAL.EDIT_DETAILS,
      component: EditDetails,
      meta: {
        title: 'Pas details aan – Mijn MediaMasters'
      },
    },
  ]
}
