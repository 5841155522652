import CmsGuard from '../../router/guards/cms'
import nl from '@/utils/language/nl.json'

const Layout = () => import('@/views/Layout')

// MISSIONS
const Missions = () => import('@/views/cms/missions/Missions')
const CreateMission = () => import('@/views/cms/missions/CreateMission')
const MissionDetails = () => import('@/views/cms/missions/MissionDetails')
const MissionDetailsCreateDia = () => import('@/views/cms/missions/diacreation/CreateDia')

//MISSION VIDEOS
const MissionVideos = () => import('@/views/cms/missionVideos/Video')
const MissionVideosCreate = () => import('@/views/cms/missionVideos/VideoCreate')
const MissionVideosEdit = () => import('@/views/cms/missionVideos/VideoEdit')


//EXTRA GAMES
const Extras = () => import('@/views/cms/extras/ExtraGames')
const EditExtraGame = () => import('@/views/cms/extras/EditExtraGame')
const CreateExtraGame = () => import('@/views/cms/extras/CreateExtraGame')
const Tags = () => import('@/views/cms/extras/Tags')

//Media quiz
const MediaQuiz = () => import('@/views/cms/quiz/MediaQuiz')
const CreateMediaQuiz = () => import('@/views/cms/quiz/MediaQuizCreate')
const EditMediaQuiz = () => import('@/views/cms/quiz/MediaQuizEdit')
const Instructions = () => import('@/views/cms/instructions/Instructions')
const CreateInstructions = () => import('@/views/cms/instructions/CreateInstructions')
const EditInstructions = () => import('@/views/cms/instructions/EditInstructions')

const Prizes = () => import('@/views/cms/prizes/Prizes')
const CreatePrize = () => import('@/views/cms/prizes/CreatePrize')
const EditPrize = () => import('@/views/cms/prizes/EditPrize')

//FAQ
const Faq = () => import('@/views/cms/faq/Faq')
const CreateFaq = () => import('@/views/cms/faq/CreateFaq')
const EditFaq = () => import('@/views/cms/faq/EditFaq')

//Week of MM Manager
const WeekOfMMManger = () => import('@/views/cms/week_of_mm/WeekOfMMManger')

const StatusMessage = () => import('@/views/cms/status_message/StatusMessage')

export const ROUTE_NAMES_CMS = Object.freeze({
  // MISSIONS
  MISSIONS: 'Missions',
  CREATE_MISSION: 'CreateMission',
  EDIT_MISSION: 'EditMission',
  MISSION_DETAILS: 'MissionDetails',
  CREATE_DIA: 'createDia',
  EDIT_DIA: 'editDia',

  //MISSION VIDEOS
  MISSION_VIDEOS: 'MissionVideos',
  MISSION_VIDEOS_CREATE: 'MissionVideosCreate',
  MISSION_VIDEOS_EDIT: 'MissionVideosEdit',

  //EXTRA GAMES
  TAGS: 'Tags',
  EXTRAS: 'Extras',
  EDIT_EXTRA_GAME: 'EditExtraGame',
  CREATE_EXTRA_GAME: 'CreateExtraGame',

  //MEDIA QUIZ
  MEDIAQUIZ: 'MediaQuiz',
  CREATE_MEDIAQUIZ: 'CreateMediaquiz',
  EDIT_MEDIAQUIZ: 'EdiMediaquiz',

  //INSTRUCTIONS
  INSTRUCTIONS: 'Instructions',
  CREATE_INSTRUCTIONS: 'CreateInstructions',
  EDIT_INSTRUCTIONS: 'EditInstructions',

  //PRIZES
  PRIZES: 'Prizes',
  CREATE_PRIZE: 'CreatePrize',
  EDIT_PRIZE: 'EditPrize',

  //FAQ
  FAQ: 'Faq',
  CREATE_FAQ: 'CreateFaq',
  EDIT_FAQ: 'EditFaq',

  //Week of MM Manager
  WEEK_OF_MM_MANAGER: 'WeekOfMMManger',

  //Status
  STATUS_MESSAGE: 'StatusMessage',
})

export const cms = {
  path: `/${nl.ROUTE.CMS}`,
  meta: {
    platform: 'cms',
    guard: CmsGuard,
    // TODO: Add the auth guard. Please note that each module requires its own guard.
  },
  redirect: `/${nl.ROUTE.CMS}/${nl.ROUTE.MISSIONS}`,
  component: Layout,
  children: [
    // MISSIONS
    {
      path: `${nl.ROUTE.MISSIONS}`,
      name: ROUTE_NAMES_CMS.MISSIONS,
      component: Missions,
      meta: {
        title: 'Missies – MediaMasters CMS'
      }
    },
    {
      path: `${nl.ROUTE.MISSIONS}/${nl.ROUTE.CREATE}`,
      name: ROUTE_NAMES_CMS.CREATE_MISSION,
      component: CreateMission,
      meta: {
        title: 'Aanmaken missie – MediaMasters CMS'
      }
    },
    {
      path: `${nl.ROUTE.MISSIONS}/${nl.ROUTE.EDIT}/:id`,
      name: ROUTE_NAMES_CMS.EDIT_MISSION,
      component: CreateMission,
      meta: {
        title: 'Aanpassen missie – MediaMasters CMS'
      }
    },
    {
      path: `${nl.ROUTE.MISSIONS}/${nl.ROUTE.DETAILS}/:id`,
      name: ROUTE_NAMES_CMS.MISSION_DETAILS,
      component: MissionDetails,
      meta: {
        title: 'Missie details – MediaMasters CMS'
      }
    },
    {
      path: `${nl.ROUTE.MISSIONS}/${nl.ROUTE.DETAILS}/:id/${nl.ROUTE.CREATE_DIA}/:type/:questionCategoryId?`,
      name: ROUTE_NAMES_CMS.CREATE_DIA,
      component: MissionDetailsCreateDia,
      meta: {
        title: 'Maak dia aan – MediaMasters CMS'
      },
      props: (route) => ({ type: route.params.type, id: route.params.id })
    },
    {
      path: `${nl.ROUTE.MISSIONS}/${nl.ROUTE.DETAILS}/:id/${nl.ROUTE.EDIT}/:type/:slideId/:questionCategoryId?`,
      name: ROUTE_NAMES_CMS.EDIT_DIA,
      component: MissionDetailsCreateDia,
      meta: {
        title: 'Pas dia aan – MediaMasters CMS'
      },
      props: (route) => ({ type: route.params.type, slideId: route.params.slideId, id: route.params.id })
    },
    //MISSION VIDEOS
    {
      path: `${nl.ROUTE.MISSION_VIDEOS}`,
      name: ROUTE_NAMES_CMS.MISSION_VIDEOS,
      component: MissionVideos,
      meta: {
        title: 'Missie videos – MediaMasters CMS'
      },
    },
    {
      path: `${nl.ROUTE.MISSION_VIDEOS}/${nl.ROUTE.CREATE}/:id?`,
      name: ROUTE_NAMES_CMS.MISSION_VIDEOS_CREATE,
      component: MissionVideosCreate,
      meta: {
        title: 'Maak missie video aan – MediaMasters CMS'
      },
    },
    {
      path: `${nl.ROUTE.MISSION_VIDEOS}/${nl.ROUTE.EDIT}/:id`,
      name: ROUTE_NAMES_CMS.MISSION_VIDEOS_EDIT,
      component: MissionVideosEdit,
      meta: {
        title: 'Pas missie video aan – MediaMasters CMS'
      },
    },    
    //EXTRAS
    {
      path: `${nl.ROUTE.EXTRAS}`,
      name: ROUTE_NAMES_CMS.EXTRAS,
      component: Extras,
      meta: {
        title: 'Extra games – MediaMasters CMS'
      },
    },
    {
      path: `${nl.ROUTE.EXTRAS}/${nl.ROUTE.CREATE}`,
      name: ROUTE_NAMES_CMS.CREATE_EXTRA_GAME,
      component: CreateExtraGame,
      meta: {
        title: 'Maak extra game aan – MediaMasters CMS'
      },
    },
    {
      path: `${nl.ROUTE.EXTRAS}/${nl.ROUTE.EDIT}/:id`,
      name: ROUTE_NAMES_CMS.EDIT_EXTRA_GAME,
      component: EditExtraGame,
      meta: {
        title: 'Pas extra game aan – MediaMasters CMS'
      },
    },
    //TAGS
    {
      path: `${nl.ROUTE.TAGS}`,
      name: ROUTE_NAMES_CMS.TAGS,
      component: Tags,
      meta: {
        title: 'Tags – MediaMasters CMS'
      },
    },
    {
      path: `${nl.ROUTE.MEDIA_QUIZ}`,
      name: ROUTE_NAMES_CMS.MEDIAQUIZ,
      component: MediaQuiz,
      meta: {
        title: 'Mediaquiz – MediaMasters CMS'
      },
    },
    {
      path: `${nl.ROUTE.MEDIA_QUIZ}/${nl.ROUTE.CREATE}`,
      name: ROUTE_NAMES_CMS.CREATE_MEDIAQUIZ,
      component: CreateMediaQuiz,
      meta: {
        title: 'Maak quiz vraag aan – MediaMasters CMS'
      },
    },
    {
      path: `${nl.ROUTE.MEDIA_QUIZ}/${nl.ROUTE.EDIT}/:id`,
      name: ROUTE_NAMES_CMS.EDIT_MEDIAQUIZ,
      component: EditMediaQuiz,
      meta: {
        title: 'Pas quiz vraag aan – MediaMasters CMS'
      },
    },
    {
      path: `${nl.ROUTE.INSTRUCTIONS}`,
      name: ROUTE_NAMES_CMS.INSTRUCTIONS,
      component: Instructions,
      meta: {
        title: 'Instructies – MediaMasters CMS'
      },
    },
    {
      path: `${nl.ROUTE.INSTRUCTIONS}/${nl.ROUTE.CREATE}`,
      name: ROUTE_NAMES_CMS.CREATE_INSTRUCTIONS,
      component: CreateInstructions,
      meta: {
        title: 'Maak instructie aan – MediaMasters CMS'
      },
    },
    {
      path: `${nl.ROUTE.INSTRUCTIONS}/${nl.ROUTE.EDIT}/:id`,
      name: ROUTE_NAMES_CMS.EDIT_INSTRUCTIONS,
      component: EditInstructions,
      meta: {
        title: 'Pas instructie aan – MediaMasters CMS'
      },
    },
    {
      path: `${nl.ROUTE.PRIZES}`,
      name: ROUTE_NAMES_CMS.PRIZES,
      component: Prizes,
      meta: {
        title: 'Prijzen – MediaMasters CMS'
      },
    },
    {
      path: `${nl.ROUTE.PRIZES}/${nl.ROUTE.CREATE}`,
      name: ROUTE_NAMES_CMS.CREATE_PRIZE,
      component: CreatePrize,
      meta: {
        title: 'Maak prijs aan – MediaMasters CMS'
      },
    },
    {
      path: `${nl.ROUTE.PRIZES}/${nl.ROUTE.EDIT}/:id`,
      name: ROUTE_NAMES_CMS.EDIT_PRIZE,
      component: EditPrize,
      meta: {
        title: 'Pas prijs aan – MediaMasters CMS'
      },
    },
    {
      path: `${nl.ROUTE.FAQ}`,
      name: ROUTE_NAMES_CMS.FAQ,
      component: Faq,
      meta: {
        title: 'FAQ – MediaMasters CMS'
      },
    },
    {
      path: `${nl.ROUTE.FAQ}/${nl.ROUTE.CREATE}`,
      name: ROUTE_NAMES_CMS.CREATE_FAQ,
      component: CreateFaq,
      meta: {
        title: 'Maak FAQ aan – MediaMasters CMS'
      },
    },
    {
      path: `${nl.ROUTE.FAQ}/${nl.ROUTE.EDIT}/:id`,
      name: ROUTE_NAMES_CMS.EDIT_FAQ,
      component: EditFaq,
      meta: {
        title: 'Pas FAQ aan – MediaMasters CMS'
      },
    },
    {
      path: `${nl.ROUTE.WEEK_OF_MM_MANAGER}`,
      name: ROUTE_NAMES_CMS.WEEK_OF_MM_MANAGER,
      component: WeekOfMMManger,
      meta: {
        title: 'Week MM Manager – MediaMasters CMS'
      },
    },
    {
      path: `${nl.ROUTE.STATUS_MESSAGE}`,
      name: ROUTE_NAMES_CMS.STATUS_MESSAGE,
      component: StatusMessage,
      meta: {
        title: 'Status bericht – MediaMasters CMS'
      },
    },
  ]
}
