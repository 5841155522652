// Merges the objects from both the passed arrays in to a single one.
export function mergeArrayObjects(lhs, rhs) {
  // Checks whether their lengths are equal
  if (lhs.length !== rhs.length) {
    console.error('The length of the arrays isn\'t equal')
  }

  // Combines the local rank data with that of the API.
  let arr = []
  for (let i = 0; i < lhs.length; i++) {
    arr.push({...lhs[i], ...rhs[i]})
  }
  return arr
}
