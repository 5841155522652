
import ApiHandler from '@/utils/handlers/ApiHandler'
import { SET_API_ERROR } from '@/store/mutations'
import { paramHelper } from '@/utils/helpers/api/paramHelper'



export function deleteById(url, id) {
  return ApiHandler.delete(`${url}${id}`, null)
    .then(response => {
      if (response) {
        return true
      } else {
        return false
      }
    }).catch(error => {
      console.log(error.response)
      return error.response
    })
}


export function tableSearch(url, payload, commit, commitName) {
  const endParams = {
    searchQuery: payload.searchQuery,
    page: payload.page,
    filters: paramHelper.createFilterString(payload.filters),
    sortString: paramHelper.createSortString(payload.sorting),
    limit: payload.limit ?? 15
  }
  const endTerms = paramHelper.createUrlParams(endParams)
  return ApiHandler.get(`${url}?${endTerms}`)
    .then(response => {
      if (response) {
        commit(commitName, response.data)
        return true
      }
      return false
    })
    .catch(error => { console.log(error.response) })
}

export function createByFormData({ commit }, url, formParams) {
  const formData = new FormData()
  for (const [key, value] of Object.entries(formParams)) {
    if (value === undefined || value === null) {
      continue
    }
    switch (value.constructor) {
      case Array:
        value.map((val, index) => {
          const stringify = !(val instanceof File || val instanceof Blob) && val instanceof Object //check if an object that isnt a file or blov
          formData.append(`${key}[${index}]`, stringify ? JSON.stringify(val) : val)
        })
        break
      default:
      {
        const stringify = !(value instanceof File || value instanceof Blob) && value instanceof Object //check if an object that isnt a file or blov
        formData.append(key, stringify ? JSON.stringify(value) : value)
      }
    }
  }
  return ApiHandler.post(url, formData)
    .then(response => {
      return response
    }).catch(error => {
      console.log(error.response)
      commit(SET_API_ERROR, error.response.data)
      return error.response
    })
}
