export default class BulkEditClass {
  schoolClassId;

  get id() {
    return this.schoolClassId
  };

  schoolClassName;

  schoolName;

  schoolPostalCode;

  bitsEarned;

  constructor({schoolClassId, schoolClassName, schoolName, schoolPostalCode, bitsEarned}) {
    this.schoolClassId = schoolClassId
    this.schoolClassName = schoolClassName
    this.schoolName = schoolName
    this.schoolPostalCode = schoolPostalCode
    this.bitsEarned = bitsEarned
  }

  static parseDataFromObject(data) {
    return new BulkEditClass(
      data
    )
  }

}
