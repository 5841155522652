//1: elementary, 2: elementary special, 3: high school, 4: high school special
export default class TeacherType {
  constructor(id, name) {
    this.id = id
    this.name = name
  }

  static parseDataFromObject(data) {
    return new TeacherType(data.id, data.name)
  }
}
