import ApiHandler from '@/utils/handlers/ApiHandler'
import ExtraGame from "@/models/ExtraGame";
import BulkEditClass from "@/models/bulkEdit/bulkEditClass";
import Mission from "@/models/Mission";

export const SET_SELECTED_TYPE = 'setSelectedType'
export const SET_SELECTED_ITEM_ID = 'setSelectedItemId'
export const SET_FOUND_CLASSES = 'setFoundClasses'
export const SET_SELECTED_CLASSES = 'setSelectedClasses'
export const TOGGLE_SELECTED_CLASS = 'toggleSelectedClass'
export const UPDATE_BULK_EDIT_SEARCH = 'updateBulkEditSearch'
export const ADD_SELECTED_CLASS = 'addSelectedClass'
export const MAKE_BULK_EDIT = 'makeBulkEdit'

export const actions = {
  async updateBulkEditSearch({commit}, searchTerm) {
    const response = ApiHandler.get(`helpdesk/school-classes/search?searchterm=${searchTerm}`)
      .then(response => {
        if (response) {
          const foundClasses = response.data.map((schoolClass) => BulkEditClass.parseDataFromObject(schoolClass));
          commit('setFoundClasses', foundClasses)
        }
      }).catch(error => {
        console.log(error)
        commit('setFoundClasses', [])
      })
    return response
  },
  async loadDailyMissions({commit}) {
    const response = ApiHandler.get(`/helpdesk/get-daily-missions`)
      .then(response => {
        if(response){
          const missions = response.data.map((mission) => Mission.parseDataFromObject(mission))
          commit('setBulkEditMissions', missions);
        }
      })
  },
  async loadExtraGames({commit}) {
    const response = ApiHandler.get(`game/extra-games/get-extra-games`)
      .then(response => {
        if (response) {
          let extraGames = response.data.map((extraGame) => ExtraGame.parseDataFromObject(extraGame));
          extraGames.sort((a, b) => a.name.localeCompare(b.name));
          commit('setBulkEditExtraGames', extraGames)
        }
      })
  },
  /**
   * Make a bulk edit of the selected classes.
   * @param commit
   * @param {BulkEditRequest} bulkEditRequest
   * @return {Promise<void>}
   */
  async makeBulkEdit({commit}, bulkEditRequest) {
    const formData = bulkEditRequest.toFormData()
    commit('setEditingStatus', undefined)
    const response = ApiHandler.post('/helpdesk/edit-bits-earned-multiple-classes', formData)
      .then(response => {
        if (response) {
          console.log(response);
          commit('setEditingStatus', bulkEditRequest.getSuccessString);
          commit('reset')
        } else {
          commit('setEditingStatus', undefined)
        }
      }).catch(error => {
        console.log("error",error);
        commit('setEditingStatus', error?.response?.data?.error_description ?? "Unable to complete request, unkonwn error")
      })
    return response
  },
  setSelectedType({commit, dispatch}, selectedType) {
    commit('setSelectedType', selectedType)
    switch (selectedType?.id ?? -1) {
      case 1:
        dispatch('loadDailyMissions')
        break
      case 2:
        dispatch('loadExtraGames')
        break
      default:
        break
    }
  },
  setSelectedItemId({commit}, selectedItemId) {
    commit('setSelectedItemId', selectedItemId)
  },
  toggleSelectedClass({commit}, schoolClass) {
    commit('toggleSelectedClass', schoolClass)
  },
  addSelectedClass({commit}, schoolClass) {
    commit('addSelectedClass', schoolClass)
  },
}
