export default class School {
  constructor(brin, city, createdAt, houseNumber, id, name, postalCode, province, schoolTypeId, streetName, updatedAt) {
    this.brin = brin
    this.city = city
    this.createdAt = createdAt
    this.houseNumber = houseNumber
    this.id = id
    this.name = name
    this.postalCode = postalCode
    this.province = province
    this.schoolTypeId = schoolTypeId
    this.streetName = streetName
    this.updatedAt = updatedAt
  }

  get schoolSuggestion() {
    return `${this.streetName} ${this.houseNumber}, ${this.city}`
  }

  static parseDataFromObject(data) {
    return new School(data.brin, data.city, data.createdAt, data.houseNumber, data.id, data.name, data.postalCode,
      data.province, data.schoolTypeId, data.streetName, data.updatedAt)
  }
}
