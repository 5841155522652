/**
 * Class for specifying the properties of the return objects from the {@link apiErrorMsgHandler.convert}-method.
 */
import i18n from '@/setup/i18n/i18n'

class ApiErrorMsgConverted {
  /**
   * @param type {string}
   * @param field {string}
   * @param message {string} these are defaults, can be overwritten
   */
  constructor(type, field, message) {
    this.type = type
    this.field = field
    this.message = message
  }
}

/**
 * The API returns error messages in the following format: {TYPE}_{FIELD}.
 * This class contains functions to process this error message to, for example, render a proper user-readable error message instead.
 */
class ApiErrorMsgHandler {
  /**
   * @type ApiErrorMsgHandler
   */
  static instance;

  constructor() {
    /**
     * @enum for the different error types.
     */
    this.ERR_TYPE = Object.freeze({
      INVALID_CREDENTIALS: 'invalid_credentials',
      INVALID_PASSCODE: '2fa_code_invalid',
      EMAIL_NOT_VERIFIED: 'email_not_verified',
    })
  }

  /**
   * @param errStr {string}
   * @returns {string}
   */
  getType(errStr) {
    return errStr.split('_', 2)[0]
  }

  /**
   * @param errStr {string}
   * @returns {string}
   */
  getField(errStr) {
    return errStr.split('_', 2)[1]
  }

  /**
   * Takes in an API error object and transforms it so that it is more readable/useful.
   * @param apiError {object}
   * @returns {string}
   */
  convert(apiError) {
    // Get the messages from the translation docs.
    const currentLocale = i18n.global.locale
    const nlMessages = i18n.global.messages[currentLocale]

    // Get the code and description of the code.
    const errorCode = apiError.error
    const errorDescription = apiError.error_description

    // The message that will be returned.
    let message = ''

    if (errorCode === this.ERR_TYPE.INVALID_CREDENTIALS) {
      message = nlMessages.API_ERRORS.INVALID_CREDENTIALS
    }

    if (errorCode === this.ERR_TYPE.INVALID_PASSCODE) {
      message = nlMessages.API_ERRORS.INVALID_PASSCODE
    }

    if (errorCode === this.ERR_TYPE.EMAIL_NOT_VERIFIED) {
      message = nlMessages.API_ERRORS.EMAIL_NOT_VERIFIED
    }

    return message
  }

  /**
   * @returns {ApiErrorMsgHandler}
   */
  static getInstance() {
    if (this.instance == null) {
      this.instance = new ApiErrorMsgHandler()
    }
    return this.instance
  }
}

/** @type {ApiErrorMsgHandler} **/
const apiErrorMsgHandler = ApiErrorMsgHandler.getInstance()

export default apiErrorMsgHandler
