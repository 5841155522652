import { createI18n } from 'vue-i18n'
import nl from '@/utils/language/nl.json'

const i18n = createI18n({
  locale: 'nl',
  fallbackLocale: 'nl',
  messages: {
    nl: nl,
  },
  silentFallbackWarn: true,
  silentTranslationWarn: true,
  legacy: false,
  globalInjection: true,
})

export default i18n
