<template>
  <div class="main-section">
    <router-view/>
  </div>
  <LoadingIndicator></LoadingIndicator>
</template>

<script>
import LoadingIndicator from '@/components/elements/LoadingIndicator'
export default {
  name: 'App',
  components: { LoadingIndicator }
}
</script>

<style lang="scss">
  @import "/assets/css/global.scss";

  .main-section {
    height: 100%;
  }
</style>
