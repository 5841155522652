import ApiHandler from '@/utils/handlers/ApiHandler'
import { SET_MISSIONS, SET_MISSION_DETAILS, SET_MISSION_CATEGORIES, SET_SLIDE_TYPES, SET_SLIDE_DETAILS, SET_NETIQUETTE_TYPES, SET_JOKER_TYPES } from '@/store/modules/cms/missions/mutations'
import { SET_API_ERROR } from '@/store/mutations'
import { tableSearch, createByFormData } from '@/utils/helpers/api/commonCallsHelper'
export const GET_MISSIONS = 'getMissions'
export const GET_MISSION_DETAILS = 'getMissionDetails'
export const GET_MISSION_CATEGORIES = 'getMissionCategories'


export const GET_SLIDE_DETAILS = 'getSlideDetails'
export const RESET_SLIDE_DETAILS = 'resetSlideDetails'
export const GET_SLIDE_TYPES = 'getSlideTypes'
export const GET_NETIQUETTE_TYPES = 'getNetiquetteTypes'
export const GET_JOKER_TYPES = 'getJokerTypes'
export const REORDER_SLIDE = 'reorderSlide'

export const CREATE_DIA_TEXT = 'createDiaText'
export const CREATE_DIA_IMAGE = 'createDiaImage'
export const CREATE_DIA_VIDEO = 'createDiaVideo'
export const CREATE_DIA_DRAG_AND_DROP = 'createDiaDragDrop'
export const CREATE_DIA = 'createDia'


export const CREATE_DIA_TEXT_ANSWER_SINGLE = 'createDiaTextAnswerSingle'
export const CREATE_DIA_TEXT_ANSWER_MULTIPLE = 'createDiaTextAnswerMultiple'
export const CREATE_DIA_IMAGE_ANSWER_SINGLE = 'createDiaImageAnswerSingle'
export const CREATE_DIA_IMAGE_ANSWER_MULTIPLE = 'createDiaImageAnswerMultiple'
export const CREATE_DIA_POLL = 'createDiaPoll'

export const CREATE_DIA_ARTICLE = 'createDiaArticle'
export const CREATE_DIA_LABYRINTH = 'createDiaLabyrinth'
export const CREATE_DIA_PHISHING = 'createDiaPhishing'
export const CREATE_DIA_NETIQUETTE = 'createDiaNetiquette'


const CREATE_SLIDE_URL = 'cms/mission/create-mission-slide'
const EDIT_SLIDE_URL = 'cms/mission/edit-mission-slide'
//default success, commits if given a name to commit to, then
//returns the response
function defaultResponseHandling(commit, response, commitName = null) {
  if (response) {
    if (commitName && commit) {
      commit(commitName, response.data)
    }
    return response
  }
  return false
}

//default handling of errors. returns the errors data
function defaultErrorHandling(commit, error) {
  console.log(error)
  commit(SET_API_ERROR, error.response.data)
  return error
}

//Creates basic slides (with no nesting of objects)
function basicSlideCreation({ commit }, data, type, slideId = null) {
  if (slideId) {
    data.id = slideId
  }
  return createByFormData({ commit }, `${slideId ? EDIT_SLIDE_URL : CREATE_SLIDE_URL}/${type}`, data)
}

export const actions = {
  getMissions({ commit }, payload) {
    return tableSearch('cms/mission/get-missions', payload, commit, SET_MISSIONS)
  },
  getMissionCategories({ commit }, data) {
    return ApiHandler.get('cms/mission/get-mission-categories')
      .then(response => defaultResponseHandling(commit, response, SET_MISSION_CATEGORIES))
      .catch(error => defaultErrorHandling(commit, error))
  },
  getJokerTypes({ commit }, data) {
    return ApiHandler.get('cms/mission/get-jokers-types')
      .then(response => defaultResponseHandling(commit, response, SET_JOKER_TYPES))
      .catch(error => defaultErrorHandling(commit, error)) },
  getMissionDetails({ commit }, id) {
    return ApiHandler.get(`cms/mission/get-mission-details/${id}`)
      .then(response => defaultResponseHandling(commit, response, SET_MISSION_DETAILS))
      .catch(error => defaultErrorHandling(commit, error))
  },
  getSlideTypes({ commit }) {
    return ApiHandler.get('cms/mission/get-mission-slide-types')
      .then(response => defaultResponseHandling(commit, response, SET_SLIDE_TYPES))
      .catch(error => defaultErrorHandling(commit, error))
  },
  getNetiquetteTypes({ commit }) {
    return ApiHandler.get('cms/mission/get-netiquette-slide-types')
      .then(response => defaultResponseHandling(commit, response, SET_NETIQUETTE_TYPES))
      .catch(error => defaultErrorHandling(commit, error))
  },
  getSlideDetails({ commit }, slideId) {
    return ApiHandler.get(`cms/mission/get-mission-slide-details/${slideId}`)
      .then(response => defaultResponseHandling(commit, response, SET_SLIDE_DETAILS))
      .catch(error => defaultErrorHandling(commit, error))
  },
  resetSlideDetails({ commit }) {
    commit(SET_SLIDE_DETAILS, null)
  },
  reorderSlide({ commit }, payload) {
    const formData = new FormData()
    formData.append('id', payload.id)
    formData.append('slideIndex', payload.slideIndex)
    return ApiHandler.post('/cms/mission/reorder-mission-slide', formData)
      .then(response => defaultResponseHandling(commit, response))
      .catch(error => defaultErrorHandling(commit, error))
  },
  createDia({ commit }, { data, type, slideId = null }) {
    return basicSlideCreation({ commit }, data, type, slideId)
  },
  createDiaText({ commit }, data, originalSlideId) {
    return basicSlideCreation({ commit }, 'text', data)
  },
  createDiaImage({ commit }, data) {
    return basicSlideCreation({ commit }, 'image', data)
  },
  createDiaVideo({ commit }, data) {
    return basicSlideCreation({ commit }, 'video', data)
  },
  createDiaTextAnswerSingle({ commit }, data) {
    return basicSlideCreation({ commit }, 'single-choice', data)
  },
  createDiaTextAnswerMultiple({ commit }, data) {
    return basicSlideCreation({ commit }, 'multiple-choice', data)
  },
  createDiaImageAnswerSingle({ commit }, data) {
    return basicSlideCreation({ commit }, 'single-choice-picture', data)
  },
  createDiaImageAnswerMultiple({ commit }, data) {
    return basicSlideCreation({ commit }, 'multiple-choice-picture', data)
  },
  createDiaPoll({ commit }, data) {
    return basicSlideCreation({ commit }, 'poll', data)
  },
  createDiaDragDrop({ commit }, data) {
    return basicSlideCreation({ commit }, 'drag-and-drop', data)
  },
  createDiaDilemma({ commit }, data) {
    return basicSlideCreation({ commit }, 'dilemma', data)
  },
  createDiaLabyrinth({ commit }, data) {
    return basicSlideCreation({ commit }, 'maze', data)
  },
  createDiaPhishing({ commit }, data) {
    return basicSlideCreation({ commit }, 'real-or-phishing', data)
  },
  createDiaNetiquette({ commit }, data) {
    return basicSlideCreation({ commit }, 'netiquette', data)
  },
  createDiaArticle({ commit }, data) {
    return basicSlideCreation({ commit }, 'article', data)
  },
}
