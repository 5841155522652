import ApiHandler from '@/utils/handlers/ApiHandler'
import {
  SET_SCHOOL_CLASSES,
  SET_TEACHER_PROFILE,
  SET_TEACHER_SETTINGS,
  SET_TEACHER_REQUESTS,
  SET_INSTRUCTIONS_PAGE,
  SET_QUESTIONS_PAGE,
  SET_FAQ_CATEGORIES,
  SET_RANKS,
  SET_USER_RANKS,
  SET_GAME_OVERVIEW
} from '@/store/modules/teacherPortal/mutations'
import { SET_API_ERROR } from '@/store/mutations'
import { createByFormData } from '@/utils/helpers/api/commonCallsHelper'

/** My Classes **/
export const GET_SCHOOL_CLASSES = 'getSchoolClasses'
export const CREATE_SCHOOL_CLASS = 'createSchoolClass'
export const GET_GAME_OVERVIEW = 'getGameOverview'
export const GET_GAME_OVERVIEW_PROF = 'getGameOverviewProf'

/** My School **/
export const GET_NEW_TEACHERS = 'getNewTeachers'
export const APPROVE_TEACHER = 'approveTeacher'
export const DECLINE_TEACHER = 'declineTeacher'
export const GET_TEACHER_REQUESTS = 'getTeacherRequests'
export const EDIT_TEACHER = 'editTeacher'
/** Teacher info **/
export const GET_TEACHER_PROFILE = 'getTeacherProfile'
export const GET_TEACHER_SETTINGS = 'getTeacherSettings'
export const EDIT_RECEIVE_GENERAL_NEWSLETTER = 'editReceiveGeneralNewsletter'
export const EDIT_RECEIVE_NEWSLETTER_WEEK_MEDIA = 'editReceiveNewsletterWeekMedia'

export const EDIT_TEACHER_ACCOUNT_DETAILS = 'editTeacherAccountDetails'
export const DELETE_TEACHER_ACCOUNT = 'deleteTeacherAccount'

export const GET_INSTRUCTIONS_PAGE = 'getInstructionsPage'
export const GET_QUESTIONS_PAGE = 'getQuestionsPage'
export const GET_FAQ_CATEGORIES = 'getFaqCategories'

export const GET_RANKS = 'getRanks'
export const GET_USER_RANKS = 'getUserRanks'

export const actions = {
  /** My Classes **/


  getSchoolClasses({ commit }, payload) {
    return ApiHandler.get('teacher/get-school-classes').then(response => {
      if (response) {
        commit(SET_SCHOOL_CLASSES, response.data)
        return true
      }
      return false
    }).catch(error => {
      console.log(error.response.data)
      commit(SET_API_ERROR, error.response.data)
    })
  },
  getGameOverview({ commit }, classId) {
    return ApiHandler.get(`teacher/get-game-overview/${classId}`).then(response => {
      if (response && response.data) {
        commit(SET_GAME_OVERVIEW, response.data)
        return true
      }
      return false
    }).catch(error => {
      console.log(error.response.data)
      commit(SET_API_ERROR, error.response.data)
    })
  },
  getGameOverviewProf({ commit }) {
    return ApiHandler.get('professional/get-game-overview/-1').then(response => {
      if (response && response.data) {
        commit(SET_GAME_OVERVIEW, response.data)
        return true
      }
      return false
    }).catch(error => {
      console.log(error.response.data)
      commit(SET_API_ERROR, error.response.data)
    })
  },
  createSchoolClass({ commit }, payload) {
    const formData = new FormData()
    formData.append('name', payload.name)
    payload.teacherIds.map((teacher, index) => {
      formData.append(`teachers[${index}]`, teacher)
    })
    formData.append('accessibilityTypeId', payload.accessibilityTypeId)
    formData.append('hasExtraSounds', payload.hasExtraSounds)
    formData.append('studentCount', payload.totalStudents)
    formData.append('classTypeId', payload.classTypeId)

    return ApiHandler.post('teacher/create-school-class', formData).then(response => {
      return response
    }).catch(error => {
      console.log(error.response.data)
      commit(SET_API_ERROR, error.response.data)
      return error.response.data
    })
  },

  /** My School **/
  approveTeacher({ commit }, teacherId) {
    const formData = new FormData()
    formData.append('teacherId', teacherId)

    return ApiHandler.post('teacher/approve-teacher', formData).then(response => {
      return !!response
    }).catch(error => {
      console.log(error.response.data)
      commit(SET_API_ERROR, error.response.data)
    })
  },
  declineTeacher({ commit }, teacherId) {
    const formData = new FormData()
    formData.append('teacherId', teacherId)

    return ApiHandler.post('teacher/decline-teacher', formData).then(response => {
      return !!response
    }).catch(error => {
      console.log(error.response.data)
      commit(SET_API_ERROR, error.response.data)
    })
  },
  getTeacherRequests({ commit }) {
    return ApiHandler.get('teacher/get-teacher-requests').then((response) => {
      if (response) {
        commit(SET_TEACHER_REQUESTS, response.data)
        return true
      }
      return false
    }).catch(error => {
      console.log(error.response.data)
      commit(SET_API_ERROR, error.response.data)
    })
  },
  getTeacherProfile({ commit }) {
    return ApiHandler.get('teacher/get-teacher-profile').then(response => {
      if (response) {
        commit(SET_TEACHER_PROFILE, response.data)
        return true
      }
      return false
    }).catch(error => {
      console.log(error.response.data)
      commit(SET_API_ERROR, error.response.data)
    })
  },
  getTeacherSettings({ commit }) {
    return ApiHandler.get('teacher/get-teacher-settings').then(response => {
      if (response) {
        commit(SET_TEACHER_SETTINGS, response.data)
        return true
      }
      return false
    }).catch(error => {
      console.log(error.response.data)
      commit(SET_API_ERROR, error.response.data)
    })
  },
  editReceiveGeneralNewsletter({ commit }, receiveGeneralNewsletter) {
    const formData = new FormData()
    formData.append('receiveGeneralNewsletter', receiveGeneralNewsletter)

    return ApiHandler.post('teacher/edit-receive-general-newsletter', formData).then(response => {
      if (response) {
        commit(SET_TEACHER_SETTINGS, response.data)
      }
      return !!response
    }).catch(error => {
      console.log(error.response.data)
      commit(SET_API_ERROR, error.response.data)
    })
  },
  editReceiveNewsletterWeekMedia({ commit }, receiveNewsletterWeekMedia) {
    const formData = new FormData()
    formData.append('receiveNewsletterWeekMedia', receiveNewsletterWeekMedia)

    return ApiHandler.post('teacher/edit-receive-newsletter-week-media', formData).then(response => {
      if (response) {
        commit(SET_TEACHER_SETTINGS, response.data)
      }
      return !!response
    }).catch(error => {
      console.log(error.response.data)
      commit(SET_API_ERROR, error.response.data)
    })
  },
  editTeacherAccountDetails({ commit }, data) {
    const formData = new FormData()
    if (data.email) {
      formData.append('email', data.email)
    }
    formData.append('oldPassword', data.oldPassword)
    if (data.newPassword) {
      formData.append('newPassword', data.newPassword)
    }

    return ApiHandler.post('teacher/edit-teacher-account-details', formData).then(response => {
      return !!response
    }).catch(error => {
      console.log(error.response.data)
      commit(SET_API_ERROR, error.response.data)
      return error.response.data
    })
  },
  editTeacher({ commit }, payload) {
    return createByFormData({ commit }, 'teacher/edit-teacher/', payload)
  },
  deleteTeacherAccount({ commit }, data) {
    const formData = new FormData()
    formData.append('password', data)

    return ApiHandler.post('teacher/delete-teacher-account', formData).then(response => {
      return response
    }).catch(error => {
      console.log(error.response.data)
      commit(SET_API_ERROR, error.response.data)
      return error.response.data
    })
  },
  getInstructionsPage({ commit }, payload) {
    return ApiHandler.get('teacher/get-instructions').then(response => {
      if (response) {
        commit(SET_INSTRUCTIONS_PAGE, response.data)
        return true
      }
      return false
    }).catch(error => {
      console.log(error.response.data)
      commit(SET_API_ERROR, error.response.data)
    })
  },
  getQuestionsPage({ commit }) {
    return ApiHandler.get('teacher/get-faq').then(response => {
      if (response) {
        commit(SET_QUESTIONS_PAGE, response.data)
        return true
      }
      return false
    }).catch(error => {
      console.log(error.response.data)
      commit(SET_API_ERROR, error.response.data)
    })
  },
  getFaqCategories({ commit }) {
    return ApiHandler.get('teacher/get-faq-categories').then(response => {
      if (response) {
        commit(SET_FAQ_CATEGORIES, response.data)
        return true
      }
      return false
    }).catch(error => {
      console.log(error.response.data)
      commit(SET_API_ERROR, error.response.data)
    })
  },
  getRanks({ commit }) {
    return ApiHandler.get('teacher/get-class-ranks').then(response => {
      if (response) {
        commit(SET_RANKS, response.data)
        return true
      }
      return false
    }).catch(error => {
      console.log(error.response.data)
      commit(SET_API_ERROR, error.response.data)
    })
  },
  getUserRanks({ commit }, { classId, filterBy }) {
    return ApiHandler.get(`teacher/get-class-rank-overview/${classId}?filterBy=${filterBy}`).then(response => {
      if (response) {
        commit(SET_USER_RANKS, response.data)
        return true
      }
      return false
    }).catch(error => {
      console.log(error.response.data)
      commit(SET_API_ERROR, error.response.data)
      return error.response.data
    })
  },
}
