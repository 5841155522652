import { formatBytes } from '../utils/helpers/ByteFormattingHelper'

export default class Video {
  constructor({ id, name, fileSize, mediaFileUrl, createdAt }) {
    this.name = name
    this.id = id
    this.fileSize = formatBytes(fileSize)
    this.fileName = mediaFileUrl
    this.createdAt = new Date(createdAt)
  }

  static parseDataFromObject(data) {
    return new Video(data)
  }
}