export const statsDummy =
{
  stats: [
    {
      title: 'Users',
      values: [
        {
          name: 'Number of teachers',
          value: 123
        },
        {
          name: 'Number of classes',
          value: 999
        },
        {
          name: 'Number of students',
          value: 12345
        }
      ]
    },
    {
      title: 'Interactions',
      values: [
        {
          name: 'Number of times completed: demo mission',
          value: 650
        },
        {
          name: 'Number of times completed: mission 1',
          value: 1520
        },
        {
          name: 'Number of times completed: mission 2',
          value: 9999
        }
      ]
    },
    {
      title: 'Interactions',
      values: [
        {
          name: 'Number of times completed: demo mission',
          value: 650
        },
        {
          name: 'Number of times completed: mission 1',
          value: 1520
        },
        {
          name: 'Number of times completed: mission 2',
          value: 9999
        }
      ]
    },
    {
      title: 'Interactions',
      values: [
        {
          name: 'Number of times completed: demo mission',
          value: 650
        },
        {
          name: 'Number of times completed: mission 1',
          value: 1520
        },
        {
          name: 'Number of times completed: mission 2',
          value: 9999
        }
      ]
    },
    {
      title: 'Interactions',
      values: [
        {
          name: 'Number of times completed: demo mission',
          value: 650
        },
        {
          name: 'Number of times completed: mission 1',
          value: 1520
        },
        {
          name: 'Number of times completed: mission 2',
          value: 9999
        }
      ]
    }
  ]
}