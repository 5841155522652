import { formatBytes } from '../utils/helpers/ByteFormattingHelper'

export default class Attachment {
  constructor(id, fileSize, fileName, createdAt, name) {
    this.name = name
    this.id = id
    this.fileSize = formatBytes(fileSize)
    this.fileName = fileName
    this.createdAt = new Date(createdAt)
  }

  static parseDataFromObject(data) {
    return new Attachment(data.id, data.fileSize, data.mediaFileUrl, data.createdAt, data.name)
  }
}

