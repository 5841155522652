import { createStore } from 'vuex'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'
import { auth } from '@/store/modules/auth'
import { teacherPortal } from '@/store/modules/teacherPortal'
import { helpdesk } from '@/store/modules/helpdesk'
import { cms } from '@/store/modules/cms'
import { missions } from '@/store/modules/cms/missions'
import {bulkEdit} from "@/store/modules/helpdesk/bulkEdit";
export default createStore({
  namespaced: false,
  modules: {
    auth,
    teacherPortal,
    helpdesk,
    cms,
    missions,
    bulkEdit,
  },
  state: () => ({
    jokers: null,
    /** Loading spinner **/
    isLoading: false,

    /** Basic Modal **/
    basicModalOptions: {},
    isBasicModalShown: false,
    activeBasicModal: undefined,

    /** Toast **/
    activeToast: undefined,

    /** Menu **/
    selectedMenuId: 0,

    /** API Error **/
    apiError: undefined,
    weekData: undefined
  }),
  mutations,
  actions,
  getters,
})
