import LocalDataHandler from '@/utils/handlers/LocalDataHandler'
import BaseGuard from '@/router/guards/base'
import { USER_TYPES } from '@/models/User'

class HelpdeskGuard extends BaseGuard {
  constructor() {
    super()
    this.typeAllowed = USER_TYPES.HELPDESK
  }

  async handleRouteEnter() {
    // The access token is refreshed by the API so there is no refresh token.
    if (!LocalDataHandler.isAuthenticated()) {
      /** keep in mind that the catch is handled in base guard (base.js) **/
      return this.routeOnFailure
    }
    return new Promise(resolve => {
      this.checkUserType() ? resolve(true) : resolve(this.routeOnFailure)
    })
  }

  checkUserType() {
    const activeUser = LocalDataHandler.getUser()
    return !!activeUser && activeUser.type === this.typeAllowed
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new HelpdeskGuard()
    }
    return this.instance
  }
}

export default HelpdeskGuard.getInstance()
