export class paramHelper {
  static createUrlParams(params, addPageIfMissing = true) {
    var str = ''
    if (!params.page && addPageIfMissing) {
      params.page = 1
    }
    for (var key in params) {
      if (!params[key] || params[key] === '') {
        continue
      }
      if (str !== '') {
        str += '&'
      }

      if (Array.isArray(params[key]) && params[key].length > 0) {
        params[key].forEach((element, index) => {
          str += key + '[' + index + ']=' + element + '&'
        })
        str = str.slice(0, -1)
      } else {
        str += key + '=' + params[key]
      }
    }

    return str
  }

  //converts sort object into the sorting string
  static createSortString(sorting) {
    return (sorting && sorting.keyName !== '')
      ? `${sorting.keyName} ${sorting.ASC ? 'ASC' : 'DESC'}` : null
  }


  static createFilterString(filters, preventingFilterType) {
    const filterIds = {}
    if (!filters) {
      return ''
    }

    filters.forEach(filter => {
      if (filter) {
        if (filter.type === preventingFilterType) return
        if (filter.type in filterIds ) {
          filterIds[filter.type].push(filter.id)
        } else {
          filterIds[filter.type] = [filter.id]
        }
      }
    })
    return JSON.stringify(filterIds)
  }
}
