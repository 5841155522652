import apiErrorMsgHandler from '@/utils/handlers/ApiErrorMsgHandler'
// import { mergeArrayObjects } from '@/utils/helpers/utils'

export const SET_JOKERS = 'setJokers'

/** Loading spinner **/
export const SET_LOADING = 'setLoading'
export const SET_CLASS_TYPES = 'setClassTypes'

/** Basic Modal **/
export const SET_BASIC_MODAL_DATA = 'setBasicModalData'
export const SET_BASIC_MODAL_SHOWN = 'setBasicModalShown'
export const RESET_BASIC_MODAL_DATA = 'resetBasicModalData'
export const SET_ACTIVE_BASIC_MODAL = 'setActiveBasicModal'
export const RESET_ACTIVE_BASIC_MODAL = 'resetActiveBasicModal'

/** Toast **/
export const SET_TOAST_DATA = 'setToastData'
export const RESET_TOAST_DATA = 'setToastData'

/** Menu  **/
export const SET_SELECTED_MENU = 'setSelectedMenu'

/** API Error **/
export const SET_API_ERROR = 'setApiError'

export const SET_WEEK_DATA = 'setWeekData'
export const mutations = {
  /** Loading spinner **/
  setLoading(state, isLoading) {
    state.isLoading = isLoading
  },

  /** Basic Modal **/
  // Modal options can include: modalContent, cancelCallback, executeCallback, data
  setBasicModalData(state, { modalOptions, activeModal }) {
    state.basicModalOptions = modalOptions
    state.isBasicModalShown = true
    state.activeBasicModal = activeModal
  },
  setBasicModalShown(state, data) {
    state.isBasicModalShown = data
  },
  resetBasicModalData(state) {
    state.basicModalOptions = {}
    state.isBasicModalShown = false
    state.activeBasicModal = undefined
  },
  // TODO: We could merge this mutation with setBasicModalData.
  setActiveBasicModal(state, data) {
    state.activeBasicModal = data
  },
  resetActiveBasicModal(state) {
    state.activeBasicModal = undefined
    state.basicModalOptions = {}
    state.isBasicModalShown = false
  },
  setJokers(state, data) {
    state.jokers = data
  },

  /** Toast **/
  setToastData(state, data) {
    state.activeToast = data
  },
  resetToastData(state) {
    state.activeToast = undefined
  },

  /** Menu **/
  setSelectedMenu(state, menuId) {
    state.selectedMenuId = menuId
  },

  /** API Error **/
  setApiError(state, data) {
    state.apiError = apiErrorMsgHandler.convert(data)
  },
  setClassTypes(state, data) {
    const classTypes = data?.sort((a, b) => {
      if (a.name.match(/^\d/) && !b.name.match(/^\d/)) {
        return 1
      }
      if (!a.name.match(/^\d/) && b.name.match(/^\d/)) {
        return -1
      }
      return a.name.localeCompare(b.name)
    });
    state.classTypes = classTypes
  },
  setWeekData(state, data) {
    state.weekData = data
  }
}
