

export const getters = {
  getExtraGames(state) {
    return state.extraGames
  },
  getTags(state) {
    return state.tags
  },
  getMediaquizes(state) {
    return state.mediaQuizzes
  },
  getInstructions(state) {
    return state.instructions
  },
  getAttachments(state) {
    return state.attachments
  },
  getVideos(state) {
    return state.videos
  },
  getPrizes(state) {
    return state.prizes
  },
  getFaqs(state) {
    return state.faqs
  },
  getMissionVideos(state) {
    return state.missionVideos
  },
}