import LocalDataHandler from '@/utils/handlers/LocalDataHandler'


export const getters = {
  /** My Classes **/
  getSchoolClasses(state) {
    return state.schoolClasses
  },
  /** Teacher settings **/
  getTeachersProfile(state) {
    return state.teacherProfile
  },
  getTeacherSettings(state) {
    return state.teacherSettings
  },

  getTeacherRequests(state) {
    return state.teacherRequests
  },
  getInstructionsPage(state) {
    return state.instructionsPage
  },
  getQuestionsPage(state) {
    return state.questionsPage
  },
  getFaqCategories(state) {
    return state.faqCategories
  },
  getRanks(state) {
    return state.ranks
  },
  getGameOverview(state) {
    return state.gameOverview
  },
  getUserRanks(state) {
    return state.userRanks
  },
  getSelectedClass(state) {
    if (state.selectedClass) {
      return state.selectedClass
    } else {
      const storedClass = LocalDataHandler.getSelectedClass()
      if (storedClass) {
        return storedClass
      } else {
        return null
      }
    }
  }
}
