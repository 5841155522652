export const getters = {
  getTeacherTypes(state) {
    return state.teacherTypes
  },
  getSearchedSchools(state) {
    return state.searchedSchools
  },
  getTeacherProfile(state) {
    return state.teacherProfile
  },

  getAuthenticated(state) {
    return state.isAuthenticated
  },

  /** Login **/
  getAuthError(state) {
    return state.authError
  },
}
