import { mutations } from './mutations'
import { actions } from './actions'
import { getters } from './getters'

export const state = () => ({
  extraGames: [],
  tags: [],
  mediaQuizzes: [],

  instructions: [],
  attachments: [],
  videos: [],

  prizes: [],
  faqs: [],
  missionVideos: [],
})

const namespaced = false

export const cms = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}