export const getters = {
  getTeachers(state) {
    return state.teachers
  },
  getTeacherDetails(state) {
    return state.teacherDetails
  },
  getClasses(state) {
    return state.classes
  },
  getClassDetails(state) {
    return state.classDetails
  },
  getRankingHelpdesk(state) {
    return state.rankingHelpdesk
  },
  getStats(state) {
    return state.stats
  },
  getProvinces(state) {
    return state.provinces
  },
  getMunicipalities(state) {
    return state.municipalities
  }
}
