export const SET_TEACHER_DETAILS = 'setTeacherDetails'
export const SET_TEACHERS = 'setTeachers'
export const SET_CLASSES = 'setClasses'
export const SET_CLASS_DETAILS = 'setClassDetails'
export const SET_RANKING_HELPDESK = 'setRankingHelpdesk'
export const SET_STATS = 'setStats'
export const SET_PROVINCES = 'setProvinces'
export const SET_MUNICIPALITIES = 'setMunicipalities'
export const mutations = {
  setTeachers(state, data) {
    state.teachers = data
  },
  setTeacherDetails(state, data) {
    state.teacherDetails = data
  },
  setClasses(state, data) {
    state.classes = data
  },
  setClassDetails(state, data) {
    state.classDetails = data
  },
  setRankingHelpdesk(state, data) {
    state.rankingHelpdesk = data
  },
  setStats(state, data) {
    state.stats = data
  },
  setProvinces(state, data) {
    state.provinces = data
  },
  setMunicipalities(state, data) {
    state.municipalities = data
  }
}
